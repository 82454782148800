import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Tipo para el estado del slice
type DownloadShpCsvState = {
    [id: string]: {
        type: string;
        progress: number;
        isDownloading: boolean;
        hasError: boolean;
        url: string[] | null; // Array de URLs
        timestamp: number;
    };
};

// Estado inicial
const initialState: DownloadShpCsvState = {};

export const downloadShpCsvSlice = createSlice({
    name: "downloadsShpCsv",
    initialState,
    reducers: {
        startDownload: (
            state,
            action: PayloadAction<{ id: string; type: string }>
        ) => {
            const { id, type } = action.payload;
            state[id] = { type, progress: 0, isDownloading: true, hasError: false, url: null, timestamp: Date.now() };
        },
        updateProgress: (
            state,
            action: PayloadAction<{ id: string; progress: number }>
        ) => {
            const { id, progress } = action.payload;
            if (state[id]) {
                state[id].progress = progress;
            }
        },
        completeDownload: (
            state,
            action: PayloadAction<{ id: string; url: string[] }>
        ) => {
            const { id, url } = action.payload;
            if (state[id]) {
                state[id].progress = 100;
                state[id].isDownloading = false;
                state[id].url = url;
            }
        },
        setError: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            if (state[id]) {
                state[id].hasError = true;
                state[id].isDownloading = false;
            }
        },
        resetDownload: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            delete state[id];
        },
    },
});

// Exportar acciones
export const {
    startDownload,
    updateProgress,
    completeDownload,
    setError,
    resetDownload,
} = downloadShpCsvSlice.actions;

// Exportar selector para usar en componentes
export const selectDownloadShpCsv = (state: RootState, id: string) =>
    state.downloadsShpCsv[id];

// Exportar reducer para agregar a la tienda
export const downloadShpCsvReducer = downloadShpCsvSlice.reducer;
