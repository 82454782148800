import { Box, Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";

export const createColumn = (index: number, t: (key: string) => string): ColumnDef<any>[] => {
  if (index === 0) {
    return [

      {
        accessorKey: "producer_name",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",


        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.producer_name")}</Typography></Box>,
        size: 250,
        cell: ({ row }) => <Typography fontSize=".8rem">{row.original.producer_name}</Typography>,

      },
      {
        accessorKey: "total_ha",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.total_ha")}</Typography></Box>,
        size: 150,
        cell: ({ row }) => <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{row.original.total_ha}</Typography></Box>,

      },

      {
        accessorKey: "sowed_percentage",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.sowed_percentage")}</Typography></Box>,
        cell: ({ row }) => { const value = row.original.sowed_percentage != null ? Math.round(row.original.sowed_percentage * 100) : 0; return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{value}%</Typography></Box> },
        size: 200,
      },
      {
        accessorKey: "latest_sowing_date",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.latest_sowing_date")}</Typography></Box>,
        cell: ({ row }) => { return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{row.original.latest_sowing_date}</Typography></Box> },
        size: 220,

      },
      {
        accessorKey: "risk_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.risk_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value = row.original.risk_percentage != null
            ? (row.original.risk_percentage * 100).toFixed(2)
            : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },

      {
        accessorKey: "estimated_yield",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.estimated_yield")}</Typography></Box>,
        cell: ({ row }) => { return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{row.original.estimated_yield}</Typography></Box> },
        size: 200,
      },

      {
        accessorKey: "estimated_yield_unit",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.estimated_yield_unit")}</Typography></Box>,
        cell: ({ row }) => { return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{row.original.estimated_yield_unit}</Typography></Box> },
        size: 230,
      },
      {
        accessorKey: "harvested_percentage",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.harvested_percentage")}</Typography></Box>,
        cell: ({ row }) => { const value = row.original.harvested_percentage != null ? Math.round(row.original.harvested_percentage * 100).toFixed(2) : "0%"; return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{value}%</Typography></Box> },
        size: 150,
      },
      {
        accessorKey: "latest_harvest_date",
        header: () => <Box sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}><Typography fontSize=".8rem" fontWeight="bold" align="center">{t("Dashboard.table.latest_harvest_date")}</Typography></Box>,
        cell: ({ row }) => { return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography fontSize=".8rem">{row.original.latest_harvest_date}</Typography></Box> },
        size: 200,
      },
    ];
  }

  if (index === 1) {
    return [
      {
        accessorKey: "producer_name",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.producer_name")}
            </Typography>
          </Box>
        ),
        size: 200,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.producer_name}</Typography>


        ),
      },
      {
        accessorKey: "crop",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.crop")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.crop}</Typography>

        ),
      },
      {
        accessorKey: "total_ha",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.total_ha")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.total_ha}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "sowed_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.sowed_percentage")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => {
          const value = row.original.sowed_percentage != null ? Math.round(row.original.sowed_percentage * 100) : 0;
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_sowing_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_sowing_date")}
            </Typography>
          </Box>
        ),
        size: 200,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_sowing_date}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "risk_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.risk_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value = row.original.risk_percentage != null ? (row.original.risk_percentage * 100).toFixed(2) : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "estimated_yield",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "estimated_yield_unit",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield_unit")}
            </Typography>
          </Box>
        ),
        size: 230,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield_unit}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "harvested_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.harvested_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value = row.original.harvested_percentage != null
            ? Math.round(row.original.harvested_percentage * 100).toFixed(2)
            : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_harvest_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_harvest_date")}
            </Typography>
          </Box>
        ),
        size: 200,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_harvest_date}</Typography>
          </Box>
        ),
      },
    ];
  }


  if (index === 2) {
    return [
      {
        accessorKey: "producer_name",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.producer_name")}
            </Typography>
          </Box>
        ),
        size: 200,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.producer_name}</Typography>

        ),
      },
      {
        accessorKey: "farm_name",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.farm_name")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.farm_name}</Typography>

        ),
      },
      {
        accessorKey: "total_ha",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.total_ha")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.total_ha}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "sowed_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.sowed_percentage")}
            </Typography>
          </Box>
        ),
        size: 190,
        cell: ({ row }) => {
          const value = row.original.sowed_percentage != null ? (row.original.sowed_percentage * 100).toFixed(2) : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_sowing_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_sowing_date")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_sowing_date}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "risk_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.risk_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value = row.original.risk_percentage != null ? (row.original.risk_percentage * 100).toFixed(2) : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "estimated_yield",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "estimated_yield_unit",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield_unit")}
            </Typography>
          </Box>
        ),
        size: 220,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield_unit}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "harvested_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.harvested_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value =
            row.original.harvested_percentage != null
              ? (row.original.harvested_percentage * 100).toFixed(2)
              : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_harvest_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_harvest_date")}
            </Typography>
          </Box>
        ),
        size: 190,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_harvest_date}</Typography>
          </Box>
        ),
      },
    ];
  }


  if (index === 3) {
    return [
      {
        accessorKey: "producer_name",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.producer_name")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.producer_name}</Typography>

        ),
      },
      {
        accessorKey: "farm_name",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.farm_name")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.farm_name}</Typography>

        ),
      },
      {
        accessorKey: "crop",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.crop")}
            </Typography>
          </Box>
        ),
        size: 120,
        cell: ({ row }) => (

          <Typography fontSize=".8rem">{row.original.crop}</Typography>

        ),
      },
      {
        accessorKey: "total_ha",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.total_ha")}
            </Typography>
          </Box>
        ),
        size: 138,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.total_ha}</Typography>
          </Box>

        ),
      },
      {
        accessorKey: "sowed_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.sowed_percentage")}
            </Typography>
          </Box>
        ),
        size: 160,
        cell: ({ row }) => {
          const value = row.original.sowed_percentage != null ? (row.original.sowed_percentage * 100).toFixed(2) : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_sowing_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_sowing_date")}
            </Typography>
          </Box>
        ),
        size: 180,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_sowing_date}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "risk_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.risk_percentage")}
            </Typography>
          </Box>
        ),
        size: 140,
        cell: ({ row }) => {
          const value = row.original.risk_percentage != null ? (row.original.risk_percentage * 100).toFixed(2) : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "estimated_yield",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield")}
            </Typography>
          </Box>
        ),
        size: 160,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "estimated_yield_unit",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.estimated_yield_unit")}
            </Typography>
          </Box>
        ),
        size: 190,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.estimated_yield_unit}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "harvested_percentage",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.harvested_percentage")}
            </Typography>
          </Box>
        ),
        size: 150,
        cell: ({ row }) => {
          const value =
            row.original.harvested_percentage != null
              ? (row.original.harvested_percentage * 100).toFixed(2)
              : "0%";
          return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography fontSize=".8rem">{value}%</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "latest_harvest_date",
        header: () => (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography fontSize=".8rem" fontWeight="bold" align="center">
              {t("Dashboard.table.latest_harvest_date")}
            </Typography>
          </Box>
        ),
        size: 160,
        cell: ({ row }) => (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography fontSize=".8rem">{row.original.latest_harvest_date}</Typography>
          </Box>
        ),
      },
    ];
  }


  return [];
};
