import { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Magic } from "magic-sdk";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ShareIcon from '@mui/icons-material/Share';
import LogoutIcon from "@mui/icons-material/Logout";
import {
  IconButton,
  Toolbar,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Drawer,
  CssBaseline,
  Box,
  AppBar,
  CircularProgress,
  Backdrop,
  Button,
  Tooltip,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import styles from "../styles/Home.module.css";
import { useAuth } from "../context/auth-context";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const drawerWidth = 60;

interface Props {
  window?: () => Window;
}

export default function ResponsiveDrawer({ window }: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, loading, logout, email } = useAuth() as any;
  const { t, i18n } = useTranslation();
  const [loadingMenu, setLoadingMenu] = useState(
    i18n.language === "es"
      ? {
        Mapas: false,
        Reporte: false,
        "Agregar Lotes": false,
        Ayuda: false,
        Inicio: false,
        Financiamiento: false,
        Compartir: false,
      }
      : {
        Maps: false,
        Reports: false,
        "Add Fields": false,
        Help: false,
        Home: false,
        Financing: false,
        Share: false
      }
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const router = useRouter();
  // const m = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY as string);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  async function signOut() {
    try {
      await logout();
      router.push("https://www.fastfarm.io/");
      localStorage.removeItem("email");
      localStorage.removeItem("did");
      localStorage.removeItem("loading");
      localStorage.removeItem("registered");
      localStorage.removeItem("user");
      localStorage.removeItem("invitedUser");
      localStorage.removeItem("dataRow");
    } catch (error) {
      console.error(error);
      router.push("error");
    }
  }

  const handleClick = (e: any) => {
    const target = e.target.innerText || e.target.id;
    localStorage.removeItem("producer");
    localStorage.removeItem("farm");
    localStorage.removeItem("valueGroup");
    localStorage.removeItem("valueFarm");
    localStorage.removeItem("optionsGroup");
    localStorage.removeItem("optionsFarm");
    localStorage.removeItem('mapState');
    localStorage.removeItem("invitedUser");
    localStorage.removeItem("dataRow");
    setLoadingMenu({ ...loadingMenu, [target]: true });
  };

  useEffect(() => {
    setLoadingMenu(
      i18n.language === "es"
        ? {
          Mapas: false,
          Reporte: false,
          "Agregar Lotes": false,
          Financiamiento: false,
          Ayuda: false,
          Inicio: false,
          Compartir: false,
        }
        : {
          Maps: false,
          Reports: false,
          "Add Fields": false,
          Financing: false,
          Help: false,
          Home: false,
          Share: false,
        }
    );
  }, [router.pathname]);

  const drawerTemporary = (
    <div
      style={{
        backgroundColor: "#005F40",
        height: "100%",
        color: "#EDEDED",
      }}
    >
      <Toolbar
        sx={{
          height: { xs: 110, md: 150 },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mt: "2%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            width: "100%",
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ borderRadius: "50%", height: "100%" }}
          >
            <ChevronLeftIcon sx={{ color: "#EDEDED" }} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: { xs: "13%", md: "0%" },
              height: { xs: "50%", md: "100%" },
              minWidth: { xs: 40, md: 40 },
              position: "relative",
            }}
          >
            <Image
              src="/leaves.png"
              alt="logo fastfarm"
              layout="fill"
              objectFit="scale-down"
            />
          </Box>

          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              ml: { xs: "1%", md: "0%" },
              height: { xs: "85%", md: "60%" },
              minWidth: { xs: 140, md: 165 },
              position: "relative",
            }}
          >
            <Image
              src="/logo-menu-tem.png"
              alt="logo fastfarm"
              layout="fill"
              objectFit="scale-down"
            />
          </Box>
        </Box>
      </Toolbar>
      <List>
        <ListItem
          button
          key="home"
          id="home"
          onClick={(ev) => {
            handleClick(ev), router.push("/dashbord");
          }}
        >
          <ListItemIcon>
            <Image src="/granero.png" alt="" width={20} height={20}></Image>
          </ListItemIcon>
          <ListItemText primary={t("Drawer.home")}></ListItemText>
        </ListItem>






        <ListItem
          button
          key="alert"
          onClick={(ev) => {
            handleClick(ev), router.push("/alert");
          }}
          disabled={
            router.pathname !== "/alert" &&
              (loadingMenu.Reporte || loadingMenu.Reports)
              ? true
              : false
          }
        >
          <ListItemIcon>
            <Image
              src="/report-home-wite.png"
              alt=""
              width={20}
              height={20}
            ></Image>
          </ListItemIcon>

          <ListItemText primary={t("Drawer.alerts")}></ListItemText>
        </ListItem>

        <ListItem
          button
          key="financing"
          onClick={(ev) => {
            handleClick(ev), router.push("/financing");
          }}
          disabled={
            router.pathname !== "/financing" &&
              (loadingMenu.Financiamiento || loadingMenu.Financing)
              ? true
              : false
          }
        >
          <ListItemIcon>
            <IconButton
              onClick={(ev) => {
                handleClick(ev), router.push("/financing");
              }}
              sx={{ padding: 0 }}
            >
              <AttachMoneyIcon sx={{ color: "#EDEDED" }} />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={t("Drawer.financing")}></ListItemText>
        </ListItem>


        <ListItem
          button
          id="maps"
          key="maps"
          onClick={(ev) => {
            handleClick(ev), router.push("/maps");
          }}
          disabled={
            router.pathname !== "/maps" &&
              (loadingMenu.Mapas || loadingMenu.Mapas)
              ? true
              : false
          }
        >
          <ListItemIcon>
            <Image
              src="/map-home-wite.png"
              alt=""
              width={20}
              height={20}
            ></Image>
          </ListItemIcon>

          <ListItemText primary={t("Drawer.maps")}></ListItemText>
        </ListItem>



        <ListItem
          button
          key="suscription"
          onClick={(ev) => {
            handleClick(ev), router.push("/suscription");
          }}
          disabled={
            router.pathname !== "/suscription" &&
              (loadingMenu["Agregar Lotes"] || loadingMenu["Add Fields"])
              ? true
              : false
          }
        >
          <ListItemIcon>
            <Image src="/addField.png" alt="" width={20} height={20}></Image>
          </ListItemIcon>

          <ListItemText primary={t("Drawer.suscription")}></ListItemText>
        </ListItem>

        <ListItem
          button
          key="share"
          id="share"
          onClick={(ev) => {
            handleClick(ev), router.push("/share");
          }}
        >
          <ListItemIcon>
            <ShareIcon sx={{ color: "#EDEDED" }} />
          </ListItemIcon>
          <ListItemText primary={t("Drawer.share")}></ListItemText>
        </ListItem>





        <ListItem
          button
          key="support"
          onClick={(ev) => {
            handleClick(ev), router.push("/support");
          }}
          disabled={
            router.pathname !== "/support" &&
              (loadingMenu.Ayuda || loadingMenu.Help)
              ? true
              : false
          }
        >
          <ListItemIcon>
            <Image src="/help-desk.png" alt="" width={20} height={20}></Image>
          </ListItemIcon>

          <ListItemText primary={t("Drawer.support")}></ListItemText>
        </ListItem>

        <ListItem
          button
          id="translate"
          key="translate"
          onClick={() => {
            i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
          }}
        >
          <ListItemIcon>
            <TranslateIcon sx={{ color: "#EDEDED" }} />
          </ListItemIcon>

          <ListItemText primary={t("Drawer.translate")}></ListItemText>
        </ListItem>

        <ListItem button key="logOut">
          <ListItemIcon>
            <IconButton onClick={() => signOut()} sx={{ padding: 0 }}>
              <LogoutIcon sx={{ color: "#EDEDED" }} />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={t("Drawer.logout")}
            onClick={() => signOut()}
          ></ListItemText>
        </ListItem>
      </List>
    </div>
  );

  const drawer = (
    <>
      <div
        style={{
          backgroundColor: "#005F40",
          height: "100%",
          color: "#EDEDED",
        }}
      >
        <List>
          <ListItem
            sx={{
              padding: 0,
              mt: "6%",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: "5%", width: 50, height: 50 }}
            >
              <MenuIcon />
            </IconButton>
          </ListItem>

          <ListItem
            button
            key="home"
            id="home"
            onClick={(ev) => {
              handleClick(ev), router.push("/dashbord");
            }}
            disabled={
              router.pathname !== "/dashbord" && loadingMenu.Inicio
                ? true
                : false
            }
            sx={{
              marginTop: "5%",
              mb: "5%",
              marginLeft: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Image
                id={
                  t("Drawer.home") !== null
                    ? String(t("Drawer.home"))
                    : undefined
                }
                src="/granero.png"
                alt=""
                width={20}
                height={20}
              ></Image>
            </ListItemIcon>
          </ListItem>


          <ListItem
            button
            key="alert"
            onClick={(ev) => {
              handleClick(ev), router.push("/alert");
            }}
            disabled={
              router.pathname !== "/alert" &&
                (loadingMenu.Reporte || loadingMenu.Reports)
                ? true
                : false
            }
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Image
                id={
                  t("Drawer.alerts") !== null
                    ? String(t("Drawer.alerts"))
                    : undefined
                }
                src="/report-home-wite.png"
                alt=""
                width={20}
                height={20}
              ></Image>
            </ListItemIcon>
          </ListItem>
          {router.pathname !== "/alert" &&
            (loadingMenu.Reporte || loadingMenu.Reports) && (
              <Backdrop
                sx={{
                  backgroundColor: "rgba(0, 0, 0,0.3)",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress
                  size={70}
                  sx={{ color: "#005f40", ml: "25%" }}
                />
              </Backdrop>
            )}

          <ListItem
            button
            key="finanacing"
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <Tooltip title={t("Drawer.financing") || ""}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <IconButton
                  onClick={(ev) => {
                    handleClick(ev), router.push("/financing");
                  }}
                  sx={{ padding: 0 }}
                >
                  <AttachMoneyIcon sx={{ color: "#EDEDED", fontSize: "1.8rem" }} />
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>


          <ListItem
            button
            id="maps"
            key="maps"
            onClick={(ev) => {
              handleClick(ev), router.push("/maps");
            }}
            disabled={
              router.pathname !== "/maps" &&
                (loadingMenu.Mapas || loadingMenu.Mapas)
                ? true
                : false
            }
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Image
                id={
                  t("Drawer.maps") !== null
                    ? String(t("Drawer.maps"))
                    : undefined
                }
                src="/map-home-wite.png"
                alt=""
                width={20}
                height={20}
              ></Image>
            </ListItemIcon>
          </ListItem>
          {router.pathname !== "/maps" &&
            (loadingMenu.Mapas || loadingMenu.Mapas) && (
              <Backdrop
                sx={{
                  backgroundColor: "rgba(0, 0, 0,0.3",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress
                  size={70}
                  sx={{ color: "#005F40", ml: "25%" }}
                />
              </Backdrop>
            )}


          <ListItem
            button
            key="suscription"
            onClick={(ev) => {
              handleClick(ev), router.push("/suscription");
            }}
            disabled={
              router.pathname !== "/suscription" &&
                (loadingMenu["Agregar Lotes"] || loadingMenu["Add Fields"])
                ? true
                : false
            }
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Image
                id={
                  t("Drawer.suscription") !== null
                    ? String(t("Drawer.suscription"))
                    : undefined
                }
                src="/addField.png"
                alt=""
                width={20}
                height={20}
              ></Image>
            </ListItemIcon>
          </ListItem>
          {router.pathname !== "/suscription" &&
            (loadingMenu["Agregar Lotes"] || loadingMenu["Add Fields"]) && (
              <Backdrop
                sx={{
                  backgroundColor: "rgba(0, 0, 0,0.3)",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress
                  size={70}
                  sx={{ color: "#005F40", ml: "25%" }}
                />
              </Backdrop>
            )}


          <ListItem
            button
            key="share"
            onClick={(ev) => {
              handleClick(ev), router.push("/share");
            }}
            disabled={
              router.pathname !== "/share" &&
                (loadingMenu.Compartir || loadingMenu.Share)
                ? true
                : false
            }
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <IconButton onClick={() => router.push("/share")} sx={{ padding: 0 }}>
                <ShareIcon sx={{ color: "#EDEDED" }} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          {router.pathname !== "/share" &&
            (loadingMenu.Compartir || loadingMenu.Share) && (
              <Backdrop
                sx={{
                  backgroundColor: "rgba(0, 0, 0,0.3)",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress
                  size={70}
                  sx={{ color: "#005F40", ml: "25%" }}
                />
              </Backdrop>
            )}

          <ListItem
            button
            key="support"
            onClick={(ev) => {
              handleClick(ev), router.push("/support");
            }}
            disabled={
              router.pathname !== "/support" &&
                (loadingMenu.Ayuda || loadingMenu.Help)
                ? true
                : false
            }
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Image
                id={
                  t("Drawer.support") ? String(t("Drawer.support")) : undefined
                }
                src="/help-desk.png"
                alt=""
                width={20}
                height={20}
              ></Image>
            </ListItemIcon>
          </ListItem>
          {router.pathname !== "/support" &&
            (loadingMenu.Ayuda || loadingMenu.Help) && (
              <Backdrop
                sx={{
                  backgroundColor: "rgba(0, 0, 0,0.3)",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress
                  size={70}
                  sx={{ color: "#005F40", ml: "25%" }}
                />
              </Backdrop>
            )}
          <ListItem
            button
            id="translate"
            key="translate"
            onClick={() => {
              i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
            }}
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <TranslateIcon sx={{ color: "#EDEDED" }} />
            </ListItemIcon>
          </ListItem>

          <ListItem
            button
            key="logOut"
            sx={{
              mb: "5%",
              borderRadius: "50%",
              width: 50,
              height: 50,
              marginLeft: "5%",
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <IconButton onClick={() => signOut()} sx={{ padding: 0 }}>
                <LogoutIcon sx={{ color: "#EDEDED" }} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
            borderRight: "none",
          },
        }}
      >
        {drawerTemporary}
      </Drawer>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          height: "60px",
          bgcolor: "#005F40",
          boxShadow: 0,
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 0 }}
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.logo}>
            <Image src="/leaves.png" alt="logo" height={50} width={50} />
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="permanent"
          open={true}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
            hideBackdrop: true,
          }}
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(0, 0, 0,0)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            },
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
