import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Tipo para el estado del slice
type DownloadState = {
    [id: number]: {
        type: string;
        progress: number;
        isDownloading: boolean;
        hasError: boolean;
        url: string | null;
        timestamp: number;
    };
};

// Estado inicial
const initialState: DownloadState = {};

export const downloadSlice = createSlice({
    name: "downloads",
    initialState,
    reducers: {
        startDownload: (
            state,
            action: PayloadAction<{ id: number; type: string }>
        ) => {
            const { id, type } = action.payload;
            state[id] = { type, progress: 0, isDownloading: true, hasError: false, url: null, timestamp: Date.now() };
        },
        updateProgress: (
            state,
            action: PayloadAction<{ id: number; progress: number }>
        ) => {
            const { id, progress } = action.payload;
            if (state[id]) {
                state[id].progress = progress;
            }
        },
        completeDownload: (
            state,
            action: PayloadAction<{ id: number; url: string }>
        ) => {
            const { id, url } = action.payload;
            if (state[id]) {
                state[id].progress = 100;
                state[id].isDownloading = false;
                state[id].url = url;
            }
        },
        setError: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            if (state[id]) {
                state[id].hasError = true;
                state[id].isDownloading = false;

            }
        },
        resetDownload: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            delete state[id];
        },
    },
});

// Exportar acciones
export const {
    startDownload,
    updateProgress,
    completeDownload,
    setError,
    resetDownload,
} = downloadSlice.actions;

// Exportar selector para usar en componentes
export const selectDownload = (state: RootState, id: number) => state.downloads[id];

// Exportar reducer para agregar a la tienda
export const downloadReducer = downloadSlice.reducer;
